import * as _ from 'lodash';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  LangChangeEvent,
  TranslateService,
} from '@ngx-translate/core';
import { DepartmentService } from 'src/app/modules/department/department.service';
import { Department } from 'src/app/modules/dashboard/shared/dashboard.model';
import { ThemeList, ThemeService } from '../../service/theme.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-department-dropdown',
  templateUrl: './department-dropdown.component.html',
  styleUrls: ['./department-dropdown.component.scss'],
})
export class DepartmentDropdownComponent
  implements OnChanges, OnDestroy
{
  @Input() departmentId: number = null;
  @Input() clearLabel = 'LOG.CLEAR';
  @Input() onlyDepartmentId: number[] = null;
  @Output() departmentChange = new EventEmitter<Department>();
  @Output() clearAllData = new EventEmitter();
  @Input() checkAllClear = false;
  @Input() currentDepartmentId: number;

  translated: string;
  showNextLevelButton = false;
  departmentTree: Department[] = null;
  selectedDepartmentID: number[] = [];
  dropdownList: Department[][] = [];
  refreshDropdown = false;
  onlyDepartmentIdSet: Set<number> = null;
  themeList: ThemeList;
  subscription: Subscription[] = [];

  constructor(
    private departmentService: DepartmentService,
    private translate: TranslateService,
    private themeService: ThemeService,
  ) {
    this.load_department_tree();
    this.translated = translate.currentLang;
    this.subscription.push(
      this.translate.onLangChange.subscribe(
        (event: LangChangeEvent) => {
          this.translated = translate.currentLang;
          this.translateDropdown();
        },
      ),
    );
    this.subscription.push(
      this.themeService.data.subscribe((theme) => {
        this.themeList = theme;
      }),
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.departmentId && this.departmentTree) {
      this.initSelectedDepartments();
    }
    if (changes.onlyDepartmentId && this.departmentTree) {
      this.filterDepartment();
    }
  }

  ngOnDestroy(): void {
    this.subscription?.forEach((item) => {
      try {
        item.unsubscribe();
      } catch (_) {}
    });
  }

  translateDropdown(): void {
    this.refreshDropdown = true;
    setTimeout(() => {
      this.refreshDropdown = false;
    }, 1);
  }

  load_department_tree() {
    const subscription = this.departmentService
      .getDepartmentTree()
      .subscribe((response) => {
        this.departmentTree = response as Department[];
        this.filterDepartment();
        this.initSelectedDepartments();
      });
    this.subscription.push(subscription);
  }

  filterDepartment() {
    if (this.onlyDepartmentId == null) {
      return;
    }

    this.onlyDepartmentIdSet = new Set(this.onlyDepartmentId);

    this.departmentTree = this.departmentTree.filter((node: any) =>
      this.onlyDepartmentIdSet.has(node.id),
    );
    this.departmentTree.forEach((node) => {
      this._filterDepartment(node);
    });
  }

  _filterDepartment(node) {
    node.children = node.children.filter((child) =>
      this.onlyDepartmentIdSet.has(child.id),
    );
    node.children.forEach((child) => {
      this._filterDepartment(child);
    });
  }

  initSelectedDepartments(indexList: number[] = []) {
    // trival case, if departmentId is null, clear last dropdown to let user select items.
    if (this.departmentId == null) {
      this.clearResult();
      return this.selectedDepartmentID;
    }

    // when call this function on first time, clear old result.
    if (indexList.length === 0) {
      this.selectedDepartmentID = [];
      this.dropdownList = [];
    }

    // clone select department index of each level.
    const indexLists = _.cloneDeep(indexList);

    // loop each department level and setup variables.
    let department: any = {
      children: this.departmentTree,
    };

    // init variables.
    const selectedDepartmentID = [];
    const dropdownList = [];

    indexList.forEach((element) => {
      dropdownList.push(department.children);
      const children = department.children[element];
      selectedDepartmentID.push(children.id);

      department = children;
    });

    // we will go to children of selected departments.
    indexList.push(null);
    selectedDepartmentID.push(null);
    dropdownList.push(null);
    const currentIndex = indexList.length - 1;

    for (let i = 0; i < department.children.length; i++) {
      const childID = department.children[i].id;

      indexList[currentIndex] = i;
      selectedDepartmentID[currentIndex] = childID;
      dropdownList[currentIndex] = department.children;

      // department id match with input. setup dropdown variable.
      if (childID === this.departmentId) {
        this.selectedDepartmentID = selectedDepartmentID;
        this.dropdownList = dropdownList;
        return this.selectedDepartmentID;
      }

      const result = this.initSelectedDepartments(indexList);
      if (result != null) {
        return result;
      }
    }

    return null;
  }

  clearData() {
    this.clearResult();
    if (this.checkAllClear) {
      this.clearAllData.emit(null);
    }
  }

  clearResult() {
    this.departmentId = null;
    this.selectedDepartmentID = [null];
    this.dropdownList = [this.departmentTree];
    this.showNextLevelButton = false;
    this.departmentChange.emit(null);
  }

  selectedDepartment(index, selectedDepartment) {
    let lastIndex: number = this.dropdownList.length - 1;

    if (index !== lastIndex) {
      this.dropdownList.splice(index + 1);
      this.selectedDepartmentID.splice(index + 1);
      lastIndex = index;
    }

    // user clear current selection on dropdown. We will get value from dropdown before.
    if (selectedDepartment == null) {
      this.selectedDepartmentID[index] = null;

      let lastDropdownSelectedDepartment: Department = null;
      if (index > 0) {
        this.departmentId = this.selectedDepartmentID[index - 1];
        lastDropdownSelectedDepartment = this.dropdownList[
          index - 1
        ].find((department) => department.id === this.departmentId);
      } else {
        this.departmentId = null;
      }

      this.showNextLevelButton = false;
      this.departmentChange.emit(lastDropdownSelectedDepartment);
    } else {
      this.departmentId = selectedDepartment.id;
      this.selectedDepartmentID[index] = selectedDepartment.id;

      this.showNextLevelButton = false;
      if (
        selectedDepartment.children != null &&
        selectedDepartment.children.length > 0
      ) {
        this.showNextLevelButton = true;
      }

      this.departmentChange.emit(selectedDepartment);
    }
  }

  showNextLevelButtonClicked() {
    const lastIndex: number = this.dropdownList.length - 1;
    const selectedDepartment: Department = this.dropdownList[
      lastIndex
    ].find(
      (department) =>
        department.id === this.selectedDepartmentID[lastIndex],
    );

    this.showNextLevelButton = false;
    this.selectedDepartmentID.push(null);
    this.dropdownList.push(selectedDepartment.children);
    // this.departmentId = null;
    // this.change.emit(null);
  }
}
